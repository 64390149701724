// const URL = "https://ticket-api.1230x.com"
// const URL = "https://ticket.syjava.com"
// const URL = "https://hls-ticket.newpictures.cn"
// const URL = "https://bshg-ticket.dlharlem.com"
// const URL = "https://ticket.whzsh.com"
// const URL = "https://ticket.1230u.com"
// const URL = "https://ticket.htcyltd.cn"
const URL = "https://ticket-api.qlwhjq.com" // 酒泉祁连苇海大景区（六分湿地）

export default {
    apiGetSecInfo: URL + '/wx/api/ticket/ScenicMessage/page', //景区信息
    apiGetSecList: URL + '/wx/api/sys/pointType/list', //景点列表
    apiGetSecItem: URL + '/wx/api/sys/tourPoint/query',
    apiGetAppKey: URL + '/wx/api/wx/applets/getJsApi',
    apiCalcDistance: URL + '/wx/api/sys/txMap/distanceMatrix', //获取定位点到景点详情的距离（一对多）
    apiGetMapConfig: URL + '/wx/api/wx/applets/getMapConfig', // 获取地图key，中心点位置，遮罩层数据
}
